var Static = {
    number_rules: {
        hu_HU: {
            decimal_separator: ',',
            thousands_separator: ' '
        },
        ro_RO: {
            decimal_separator: ',',
            thousands_separator: ' '
        },
        sk_SK: {
            decimal_separator: ',',
            thousands_separator: ' '
        },
        rs_SR: {
            decimal_separator: ',',
            thousands_separator: ' '
        }
    },

    currency_rules: {
        HUF: {
            iso: "HUF",
            symbol: "Ft",
            decimals: 0,
            format: "%number %symbol"
        },
        RON: {
            iso: "RON",
            symbol: "LEI",
            decimals: 2,
            format: "%number %symbol"
        },
        EUR: {
            iso: "EUR",
            symbol: "€",
            decimals: 2,
            format: "%symbol %number"
        },
        RSD: {
            iso: "RSD",
            symbol: "DIN",
            decimals: 2,
            format: "%number %symbol"
        },
        ROL: {
            iso: "ROL",
            symbol: "LEI",
            decimals: 2,
            format: "%number %symbol"
        }
    }
};

window.Static = Static;

export default Static;
