;
(function (App) {
    var messages = {
        "please choose": "please choose",
        "required": "required",
        // cart
        "You can only enter a number as quantity!": "You can only enter a number as quantity!",
        "Product added to cart.": "Product added to cart.",
        "Close": "Close",
        "Go to cart": "Go to cart",
        "Product updated in cart.": "Product updated in cart.",
        "Product deleted in cart.": "Product deleted in cart.",
        "Place order": "Place order",
        // booking
        "Car data": "Car data",
        "Cars to choose from": "Cars to choose from",
        "License plate number": "License plate number",
        "Required": "Required",
        "Brand": "Brand",
        "Type": "Type",
        "Model": "Model",
        "Van": "Van",
        "Your car cannot be found in the list? Click here for help!": "Your car cannot be found in the list? Click here for help!",
        "Save car to my cars": "Save car to my cars",
        "Choose wheel": "Choose wheel",
        "From home": "From home",
        "From cart": "From cart",
        "From hotel": "From hotel",
        "If you have stored wheels, you can find them here": "If you have stored wheels, you can find them here",
        "These are the wheels that we've found in our hotel. Choose the ones you'd like to use.": "These are the wheels that we've found in our hotel. Choose the ones you'd like to use.",
        "Search": "Search",
        "Stored wheels couldn't be found. If you think you do have stored wheels but the search didn't find them, please click": "Stored wheels couldn't be found. If you think you do have stored wheels but the search didn't find them, please click",
        "here": "here",
        "to contact us!": "to contact us!",
        "No products found in your cart. If you would like to select a wheel from your cart, please add the product to your cart!": "No products found in your cart. If you would like to select a wheel from your cart, please add the product to your cart!",
        "Selected size": "Selected size",
        "Quantity": "Quantity",
        "Only wheels of the same size can be selected(Maximum 4 qty.).": "Only wheels of the same size can be selected(Maximum 4 qty.).",
        "qty.": "qty.",
        "Choose which product in the cart you want to use": "Choose which product in the cart you want to use",
        "How many wheels do you want to change?": "How many wheels do you want to change?",
        "Is the tyre on a rim?": "Is the tyre on a rim?",
        "The diameter of your tyres?": "The diameter of your tyres?",
        "Diameter": "Diameter",
        "Make seasonal tyre change easier with MARSO tyre hotel where your tyres are safely preserved and stored in expert hands until the next change. So all you need to do is book an appointment, and when you arrive, everything will be ready for you. For more information click": "Make seasonal tyre change easier with MARSO tyre hotel where your tyres are safely preserved and stored in expert hands until the next change. So all you need to do is book an appointment, and when you arrive, everything will be ready for you. For more information click",
        "Tyre hotel": "Tyre hotel",
        "Next": "Next",
        "Options": "Options",
        "Choose service": "Choose service",
        "Choose service package": "Choose service package",
        "Choose date": "Choose date",
        "Explanation": "Explanation",
        "Available dates": "Available dates",
        "Few available dates": "Few available dates",
        "No available dates": "No available dates",
        "Not available days": "Not available days",
        "Username": "Username",
        "Phone number": "Phone number",
        "Your booking details": "Your booking details",
        "Book and add to cart": "Book and add to cart",
        "You can enter a minimum of 1 and a maximum of %maximum% quantities.": "You can enter a minimum of 1 and a maximum of %maximum% quantities.",
        "Unfortunately, it is not possible to book an appointment online at our selected service, so after placing your order, we will call you on the phone number you provided to arrange an appointment.": "Unfortunately, it is not possible to book an appointment online at our selected service, so after placing your order, we will call you on the phone number you provided to arrange an appointment.",
        "Phone appointment.": "Phone appointment.",
        "If you have a comment on the booking, you can write here.": "If you have a comment on the booking, you can write here.",
        "Add new car": "Add new car",
        "No results found.": "No results found.",
        "Please fill this field!": "Please fill this field!",
        "The zip code need to be four number!": "The zip code need to be four number!",
        "The zip code need to be six number!": "The zip code need to be six number!",
        // marso maps
        "Your position": "Your position",
        "Go to the garage page": "Go to the garage page",
        "Closed": "Closed",
        "Monday": "Monday",
        "Tuesday": "Tuesday",
        "Wednesday": "Wednesday",
        "Thursday": "Thursday",
        "Friday": "Friday",
        "Saturday": "Saturday",
        "Sunday": "Sunday",
        "Please enter a valid e-mail!": "Please enter a valid e-mail!",
        // validator
        "This field is required!": "This field is required!",
        "This value is too short!": "This value is too short!",
        "This value is too long!": "This value is too long!",
        "This value need to be %len% long!": "This value need to be %len% long!",
        "This value not a number!": "This value not a number!",
        "This value is too low!": "This value is too low!",
        "This value is too high!": "This value is too high!",
        "This value not an email address!": "This value not an email address!",
        "This value is not valid!": "This value is not valid!",
        "The format must be: %mask%": "The format must be: %mask%",
        // rim search
        "coll": "coll",
        "choose brand": "choose brand",
        "choose type": "choose type",
        "choose model": "choose model",
        "choose size": "choose size",
        "No or indirect": "N or indirect",
        "Direct": "Direct",
        // search
        "Error in debug logging, exiting.": "Error in debug logging, exiting.",
        "A valid template name was not specified.": "A valid template name was not specified.",
        "There was an issue with querying the server.": "There was an issue with querying the server.",
        "Results must be an array to use maxResults setting": "Results must be an array to use maxResults setting",
        "The method you called is not defined.": "The method you called is not defined.",
        "Cannot search. No source used, and Semantic API module was not included": "Cannot search. No source used, and Semantic API module was not included",
        "Your search returned no results": "Your search returned no results",
        // checkout
        "If you filled the company field, fill this field also!": "If you filled the company field, fill this field also!",
        // wheel
        "Please choose a rim first!": "Please choose a rim first!",
        "Please choose a tyre first!": "Please choose a tyre first!",
        "": ""
    };

    App.Translator.extendDictionary(messages);

})(App);
