(function ($, App) {
    'use strict';

    // create Validator object
    var Validator = {
        data: null,
        errors: [],
        valid: true,
        skip: false,
        errorMessages: {
            'required': 'This field is required!',
            'minLen': 'This value is too short!',
            'maxLen': 'This value is too long!',
            'equalLen': 'This value need to be %len% long!',
            'isNum': 'This value not a number!',
            'minValue': 'This value is too low!',
            'maxValue': 'This value is too high!',
            'email': 'This value not an email address!',
            'in': 'This value is not valid!',
            'mask': 'The format must be: %mask%'
        },
        isValid: function () {
            return this.valid;
        },
        validate: function (data) {
            this.valid = true;
            this.skip = false;
            this.errors = [];
            this.data = data;
            return this;
        },
        getError: function () {
            return this.errors.shift();
        },
        required: function (error) {
            error = error || App.t(this.errorMessages.required);
            if (this.skip) { return this; }

            if (0 == this.data.length) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }
            return this;
        },
        minLen: function (minLen, error) {
            error = error || App.t(this.errorMessages.minLen);
            if (this.skip) { return this; }

            if (this.data.length < minLen) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }
            return this;
        },
        maxLen: function (maxLen, error) {
            error = error || App.t(this.errorMessages.maxLen);
            if (this.skip) { return this; }

            if (this.data.length > maxLen) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }
            return this;
        },
        equalLen: function (len, error) {
            error = error || App.t(this.errorMessages.equalLen, { '%len%': len });
            if (this.skip) { return this; }

            if (this.data.length != len) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }
            return this;
        },
        isNum: function (error) {
            error = error || App.t(this.errorMessages.isNum);
            if (this.skip) { return this; }

            if (!(!isNaN(parseFloat(this.data)) && isFinite(this.data))) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }
            return this;
        },
        minValue: function (minValue, error) {
            error = error || App.t(this.errorMessages.minValue);
            if (this.skip) { return this; }

            if (minValue > this.data) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }
            return this;
        },
        maxValue: function (maxValue, error) {
            error = error || App.t(this.errorMessages.maxValue);
            if (this.skip) { return this; }

            if (maxValue < this.data) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }
            return this;
        },
        email: function (error) {
            error = error || App.t(this.errorMessages.email);
            if (this.skip) { return this; }

            if (null === this.data.match(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)+$/i)) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }
            return this;
        },
        ifNotEmpty: function () {
            if ('' == this.data) {
                this.skip = true;
            }
            return this;
        },
        in: function (array, error) {
            error = error || App.t(this.errorMessages.in);
            if (this.skip) { return this; }

            if (-1 == array.indexOf(this.data)) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }

            return this;
        },
        mask: function (mask, error) {
            error = error || App.t(this.errorMessages.mask, { '%mask%': mask });
            if (this.skip) { return this; }

            var re = new RegExp(mask, 'g');
            if (null === this.data.match(re)) {
                this.errors.push(error);
                this.valid = false;
                this.skip = true;
            }
            return this;
        }
    };

    // init
    App.Validator = Validator;

})(jQuery, App);
