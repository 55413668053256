;

(function (App) {
    if ('hu_HU' != App.Translator.locale) {
        return;
    }

    var messages = {
        "please choose": "kérlek válassz",
        "required": "kötelező",
        // cart
        "You can only enter a number as quantity!": "Csak számot adhatsz meg darabszámként!",
        "Product added to cart.": "Termék kosárba téve.",
        "Close": "Bezár",
        "Go to cart": "Ugrás a kosárhoz",
        "Product updated in cart.": "Termék frissítve a kosárban.",
        "Product deleted in cart.": "Termék törölve a kosárból.",
        "Place order": "Rendelés feladása",
        // booking
        "Car data": "Autó adatok",
        "Cars to choose from": "Választható autók",
        "License plate number": "Rendszám",
        "Required": "Kötelező",
        "Brand": "Márka",
        "Type": "Típus",
        "Model": "Modell",
        "Van": "Kisteher",
        "Your car cannot be found in the list? Click here for help!": "Nem található az autód a listában? Kattints ide, segítünk!",
        "Save car to my cars": "Autó mentése az autóim közé",
        "Choose wheel": "Abroncs választás",
        "From home": "Hozott abroncs",
        "From cart": "Kosárból",
        "From hotel": "Abroncs hotelból",
        "If you have stored wheels, you can find them here": "Amennyiben nálunk vannak tárolva az abroncsaid itt megkeresheted őket",
        "These are the wheels that we've found in our hotel. Choose the ones you'd like to use.": "Ezeket találtuk meg hotelünkben, válaszd ki melyiket használod a szolgáltatáshoz",
        "Search": "Keresés",
        "Stored wheels couldn't be found. If you think you do have stored wheels but the search didn't find them, please click": "Nem találtunk eltárolva abroncsokat az autódhoz. Amennyiben úgy gondolod, hogy mégis van eltárolva, de a kereső nem találta azokat, kérlek kattints",
        "here": "ide",
        "to contact us!": "és vedd fel velünk a kapcsolatot!",
        "No products found in your cart. If you would like to select a wheel from your cart, please add the product to your cart!": "Nem találtunk a kosaradban terméket. Amennyiben a kosaradból szeretnél abroncsot választani, kérlek rakd a kosaradba a számodra megfelő terméket!",
        "Selected size": "Választott méret",
        "Quantity": "Darabszám",
        "Only wheels of the same size can be selected(Maximum %quantity% qty.).": "Csak azonos méretű abroncsok választása megengedett(Maximum %quantity% db).",
        "qty.": "db",
        "Choose which product in the cart you want to use": "Válaszd ki melyik kosárban lévő terméket használod a szolgáltáshoz",
        "How many wheels do you want to change?": "Hány darab abroncsot szereltetsz?",
        "Is the tyre on a rim?": "Felnin van az abroncs?",
        "The diameter of your tyres?": "Abroncsod átmérője?",
        "Diameter": "Átmérő",
        "Make seasonal tyre change easier with MARSO tyre hotel where your tyres are safely preserved and stored in expert hands until the next change. So all you need to do is book an appointment, and when you arrive, everything will be ready for you. For more information click": "Tedd könnyebbé az évszakonkénti gumiabroncs-cserét a MARSO abroncshotelével, ahol szakértő kezekben biztonságosan megőrizzük és tároljuk gumiabroncsaid a következő cseréig. Így csak annyi a dolgod, hogy időpontot foglalj, és érkezésekor már minden előkészítve vár, hogy felszereljük autódra. Bővebb információkért kattints",
        "Tyre hotel": "Abroncs hotel",
        "Next": "Tovább",
        "Options": "Lehetőségek",
        "Choose service": "Válassz szervizt",
        "Choose service package": "Válassz szerviz csomagot",
        "Choose date": "Válassz időpontot",
        "Explanation": "Jelmagyarázat",
        "Available dates": "Szabad időpontok",
        "Few available dates": "Kevés szabad időpont",
        "No available dates": "Nincs szabad időpont",
        "Not available days": "Nem foglalható napok",
        "Username": "Felhasználónév",
        "Phone number": "Telefonszám",
        "Your booking details": "Foglalás adatok",
        "Book and add to cart": "Foglalás és kosárba rakás",
        "You can enter a minimum of 1 and a maximum of %maximum% quantities.": "Minimum 1 és maximum %maximum% darabszám megadása lehetséges.",
        "Unfortunately, it is not possible to book an appointment online at our selected service, so after placing your order, we will call you on the phone number you provided to arrange an appointment.": "Sajnos a kiválasztott szervizünkben nem lehetséges online időpontot foglalni, ezért a rendelésed leadása után az általad megadott telefonszámon, meg fogunk keresni az időpont-egyeztetés miatt.",
        "Phone appointment.": "Telefonos időpont egyeztetés.",
        "If you have a comment on the booking, you can write here.": "Ha megjegyzésed van a foglalással kapcsolatban, ide írhatsz.",
        "Add new car": "Új autó hozzáadása",
        "No results found.": "Nincs találat.",
        "Please fill this field!": "Kérlek töltsd ki ezt a mezőt!",
        "The zip code need to be four number!": "Az irányítószámnak négy jegyűnek kell lennie!",
        "The zip code need to be six number!": "Az irányítószámnak hat jegyűnek kell lennie!",
        // marso maps
        "Your position": "A te pozíciód",
        "Go to the garage page": "Tovább a szerviz oldalára",
        "Closed": "Zárva",
        "Monday": "Hétfő",
        "Tuesday": "Kedd",
        "Wednesday": "Szerda",
        "Thursday": "Csütörtök",
        "Friday": "Péntek",
        "Saturday": "Szombat",
        "Sunday": "Vasárnap",
        "Please enter a valid e-mail!": "Kérlek helyes email címet adj meg!",
        // validator
        "This field is required!": "A mező kitöltése kötelező!",
        "This value is too short!": "A mező tartalma túl rövid!",
        "This value is too long!": "A mező tartalma túl hosszú!",
        "This value need to be %len% long!": "A mező tartalmának hossza %len% kell legyen!",
        "This value not a number!": "A mező tartalmának számnak kell lennie!",
        "This value is too low!": "A mező értéke túl kicsi!",
        "This value is too high!": "A mező értéke túl nagy!",
        "This value not an email address!": "A mező tartalma nem tűnik email címnek!",
        "This value is not valid!": "Helytelen adat!",
        "The format must be: %mask%": "A formátumnak egyeznie kell ezzel: %mask%",
        // rim search
        "coll": "coll",
        "choose brand": "válassz márkát",
        "choose type": "válassz típust",
        "choose model": "válassz modellt",
        "choose size": "válassz méretet",
        "No or indirect": "Nincs vagy indirekt",
        "Direct": "Direkt",
        // search
        "Error in debug logging, exiting.": "Error in debug logging, exiting.",
        "A valid template name was not specified.": "A valid template name was not specified.",
        "There was an issue with querying the server.": "Sajnos a szerver oldali hibába futottál.",
        "Results must be an array to use maxResults setting": "Results must be an array to use maxResults setting",
        "The method you called is not defined.": "The method you called is not defined.",
        "Cannot search. No source used, and Semantic API module was not included": "Cannot search. No source used, and Semantic API module was not included",
        "Your search returned no results": "A keresés nem hozott eredményt",
        // checkout
        "If you filled the company field, fill this field also!": "Ha megadtál cégnevet, akkor ezt a mezőt is ki kell töltened!",
        // wheel
        "Please choose a rim first!": "Kérlek válassz egy felnit először!",
        "Please choose a tyre first!": "Kérlek válassz egy abroncsot először!",
        "": ""

    };

    App.Translator.extendDictionary(messages);

})(App);
