// Don't break on browsers without console.log();
(function (global) {
    'use strict';
    if (!global.console) {
        global.console = {};
    }
    var con = global.console;
    var prop, method;
    var dummy = function () { };
    var properties = ['memory'];
    var methods = [
        'assert','clear','count','debug','dir','dirxml','error','exception','group',
        'groupCollapsed','groupEnd','info','log','markTimeline','profile','profiles','profileEnd',
        'show','table','time','timeEnd','timeline','timelineEnd','timeStamp','trace','warn'
    ];
    while ((prop = properties.pop())) {
        if (!con[prop]) {
            con[prop] = {};
        }
    }
    while ((method = methods.pop())) {
        if (!con[method]) {
            con[method] = dummy;
        }
    }
    // Using `this` for web workers & supports Browserify / Webpack.
})(typeof window === 'undefined' ? this : window);

window.App = {};

var init = function (App) {
    // static config
    App.StaticConfig = Static;

    var l = document.getElementsByTagName("html")[0].getAttribute("data-locale");

    // create Translator object
    var Translator = {
        dictionary: {},
        locale: l
    };

    Translator.extendDictionary = function(dictionary) {
        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {
                this.dictionary[key] = dictionary[key];
            }
        }
    };

    Translator.translate= function(key) {
        // TODO make this function to handle arguments
        if (undefined != Translator.dictionary[key]) {
            return Translator.dictionary[key];
        }
        return key;
    };

    App.Translator = Translator;

    // translator shortcut
    App.t = function(key, args) {
        args = args || {};
        if (args) {
            key = App.Translator.translate(key);
            return key.replace(/(%\w+%)/g, function (match, key) {
                return args.hasOwnProperty(key) ? args[key] : key;
            });
        }
        return App.Translator.translate(key);
    };
};

init(App);

(function($,App){

    // create message object
    var Messages = {
        outputType: 'alert',
        outputPanel: null,
        messageNum: 0,
        init: function(panel) {
            if (panel.length) {
                this.outputPanel = panel;
                this.outputType = 'html';
                this.outputPanel.on('click', '.close.icon', function(event){
                    $(this)
                        .closest('.message')
                        .transition('fade', function(){
                            $(this).remove();
                        })
                    ;
                });
            }
        },
        add: function(message, status, timeout) {
            status = status || 'info';
            timeout = timeout || 5;
            if ('html' == this.outputType) {
                this.outputHtml(message, status, timeout);
            } else {
                this.outputAlert(message);
            }
        },
        outputAlert: function(message) {
            alert(message);
        },
        outputHtml: function(message, status, timeout) {
            if (!this.outputPanel.hasClass('active')) {
                this.outputPanel.addClass('active');
            }
            this.addHtmlMessage(message, status, timeout);
        },
        addHtmlMessage: function(message, status, timeout) {
            var element = $('<div id="message-' + this.messageNum + '" class="ui icon message ' + status + '"><i class="comment icon"></i><div class="content">' + message + '</div><i class="close icon"></i></div>');
            this.outputPanel.append(element);
            if (0 < timeout) {
                this.removeHtmlMessage(element, timeout);
            }
            this.messageNum++;
        },
        removeHtmlMessage: function(element, timeout) {
            timeout = timeout || 0;
            if (0 < timeout) {
                setTimeout(function() {
                    element.find('.close.icon').click();
                }, timeout * 1000);
            } else {
                element.find('.close.icon').click();
            }
        }
    };

    var Utils = {
        formatCurrency: function(value, currency, locale) {
            currency = currency || 'HUF';
            locale = locale || 'hu_HU';
            var currencyRules = App.StaticConfig.currency_rules[currency];
            var numberRules = App.StaticConfig.number_rules[locale];
            return this._formatCurrency(value, currencyRules, numberRules);
        },
        _formatCurrency: function(value, currencyRules, numberRules) {
            currencyRules = currencyRules || App.StaticConfig.currency_rules['HUF'];
            numberRules = numberRules || App.StaticConfig.number_rules['hu_HU'];
            value = this._formatNumber(value, numberRules, currencyRules.decimals);
            return currencyRules.format.replace('%number', value).replace('%symbol', currencyRules.symbol);
        },
        formatNumber: function(number, locale, decimals) {
            decimals = decimals || 0;
            locale = locale || 'hu_HU';
            var numberRules = App.StaticConfig.number_rules[locale];
            return this._formatNumber(number, numberRules, decimals);
        },
        _formatNumber: function(number, numberRules, decimals) {
            decimals = decimals || 0;
            numberRules = numberRules || App.StaticConfig.number_rules['hu_HU'];

            number = number.toFixed(decimals).split('.');
            var integer = number[0];
            var len = integer.length;
            var result = '';
            while(3 < len) {
                len = len - 3;
                result = numberRules.thousands_separator + integer.slice(len,len+3) + result;
            }
            result = integer.slice(0,len) + result;

            if (number[1]) {
                result += numberRules.decimal_separator + number[1];
            }

            return result;
        },
        loading: function(element, wrapperClasses, iconSize, maxHeight, minHeight, iconType) {
            // empty the element and put a loading image inside
            iconSize = iconSize || 'huge';
            iconType = iconType || 'notched circle';
            var height = element.height();
            if ( maxHeight && (height > maxHeight) ) {
                height = maxHeight;
            }
            if ( (10 > height) && minHeight) {
                height = minHeight;
            }
            wrapperClasses = wrapperClasses || '';
            wrapperClasses = 'working ' + wrapperClasses;

            var loadingHtml = '<div class="' + wrapperClasses + '" style="height:' + height + 'px;line-height:' + height + 'px;"><i class="' + iconType + ' loading ' + iconSize + ' icon"></i></div>';
            element.html(loadingHtml);
        },
        sendForm: function(url, params) {
            // send a normal HTML form (non-ajax)
            var form = $("<form>")
                .attr("method", "post")
                .attr("action", url);
            $.each(params, function(name, value) {
                $("<input type='hidden'>")
                    .attr("name", name)
                    .attr("value", value)
                    .appendTo(form);
            });
            form.appendTo("body");
            form.submit();
        },
        scrollTo: function(element, offset, speed) {
            speed = speed || '400';
            offset = offset || 0;
            offset += element.offset().top;
            var context = document.scrollingElement;
            if (context) {
                $(context).animate({
                    scrollTop: offset
                }, speed);
            }
        },
        scrollRight: function(element, offset, speed, context) {
            speed = speed || '400';
            offset = offset || 0;
            offset += element.scrollLeft();
            context = context || $('body');
            context.animate({
                scrollLeft: offset + 'px'
            }, speed);
        },
        scrollLeft: function(element, offset, speed, context) {
            speed = speed || '400';
            offset = offset || 0;
            offset = element.scrollLeft() - offset;
            context = context || $('body');
            context.animate({
                scrollLeft: offset + 'px'
            }, speed);
        },
        calculateHorizontalScrollShift: function(frame) {
            var item = frame.find('.hs-item:not(.invisible):first');
            var frameWidth = frame.width();
            var itemWidth = item.outerWidth(true);
            var unite = Math.floor(frameWidth / itemWidth);
            return unite * itemWidth;
        }
    };

    var Cart = {
        items: [],
        total: 0,
        subtotal: 0,
        shipping: 0,
        urls: {
            load: '/ajax/cart-api/get-cart',
            getItems: '/ajax/cart-api/get-items',
            add: '/ajax/cart-api/add-item',
            update: '/ajax/cart-api/update-item',
            remove: '/ajax/cart-api/remove-item'
        },
        init: function() {
            // load cart
            Cart.load();
        },

        load: function(callback) {
            var callback = callback || function() {};
            $.ajax({
                type: "GET",
                dataType: 'json',
                url: Cart.urls.load,
                success: function (data) {
                    if (typeof data !== 'undefined' && data.status) {
                        Cart.items = data.items;
                        Cart.subtotal = data.subtotal;
                        Cart.shipping = data.shipping;
                        Cart.total = data.total;
                        Cart._updateCounter();
                        callback(true, data);
                        return;
                    }
                    callback(false, data);
                },
                error: function (data) {
                    callback(false, data);
                }
            });
        },
        getItemQuantity: function(key) {
            return Cart.items[key]['quantity'];
        },
        getSubtotal: function(needFormatting, currency, locale) {
            if (needFormatting) {
                return App.Utils.formatCurrency(Cart.subtotal, currency, locale);
            }
            return Cart.subtotal;
        },
        getShippingPrice: function(needFormatting, currency, locale) {
            if (needFormatting) {
                return App.Utils.formatCurrency(Cart.shipping, currency, locale);
            }
            return Cart.shipping;
        },
        getTotal: function(needFormatting, currency, locale) {
            if (needFormatting) {
                return App.Utils.formatCurrency(Cart.total, currency, locale);
            }
            return Cart.total;
        },
        count: function() {
            var key;
            var count = 0;
            for (key in Cart.items) {
                if (typeof Cart.items[key]['shipping'] === 'undefined') {
                    count += parseInt(Cart.items[key]['quantity']);
                }
            }
            return count;
        },
        itemCount: function() {
            var count = 0;
            for (key in Cart.items) {
                if (typeof Cart.items[key]['shipping'] === 'undefined') {
                    count++;
                }
            }
            return count;
        },
        _updateCounter: function() {
            // replace counter in the menu
            var counter = Cart.count();
            $('#my-cart-counter').text(counter);
            if (0 == counter) {
                $('#my-cart-counter').addClass('invisible');
            } else {
                $('#my-cart-counter').removeClass('invisible');
            }
        },
        add: function(productSku, quantity, callback) {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: Cart.urls.add,
                data: { quantity: quantity, product_sku: productSku },
                success: function (data) {
                    if (data.status) {
                        Cart.load(function(){
                            callback(true, data);

                            Cart.changeEvent($('#quantity-' + productSku), quantity);
                            Cart.matomoCallback();
                        });

                        return;
                    }

                    callback(false, data);
                },
                error: function (data) {
                    callback(false, data);
                }
            });
        },
        addRequest: function(productSku, quantity, callback) {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: Cart.urls.add,
                data: { quantity: quantity, product_sku: productSku, type: 'service' },
                success: function (data) {
                    if (data.status) {
                        Cart.load(function(){
                            callback(true, data);
                        });
                        return;
                    }

                    callback(false, data);
                },
                error: function (data) {

                    callback(false, data);
                }
            });
        },
        update: function(key, quantity, callback) {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: Cart.urls.update,
                data: { quantity: quantity, key: key },
                success: function (data) {
                    if (data.status) {
                        Cart.load(function(){
                            callback(true, data);

                            Cart.changeEvent($('input[data-key="' + key + '"]'), quantity);
                            Cart.matomoCallback();
                        });

                        return;
                    }
                    callback(false, data);
                },
                error: function (data) {
                    callback(false, data);
                }
            });
        },
        remove: function(key, callback) {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: Cart.urls.remove,
                data: { key: key },
                success: function (data) {
                    if (data.status) {
                        Cart.load(function(){
                            callback(true, data);

                            Cart.changeEvent($('input[data-key="' + key + '"]'), 0);
                            Cart.matomoCallback();
                        });

                        return;
                    }
                    callback(false, data);
                },
                error: function (data) {
                    callback(false, data);
                }
            });
        },
        changeEvent(input, quantity) {
            // facebook pixel tracking
            if (typeof window.fbq !== 'undefined') {
                window.fbq('track', 'AddToCart', {
                    value: quantity * input.data('unit-price'),
                    currency: input.data('currency'),
                    content_type: 'product',
                    contents: [{id: input.data('product-id'), quantity: quantity}]
                });
            }
        },
        matomoCallback() {
            if (typeof _paq === 'undefined') {
                return;
            }

            for (const key in Cart.items) {
                if (Object.hasOwnProperty.call(Cart.items, key)) {
                    const item = Cart.items[key];
                    if (!Object.hasOwnProperty.call(item, 'shipping')) {
                        _paq.push(['addEcommerceItem',
                            item.sku, // (Required) productSKU
                            "", // (Optional) productName
                            [], // (Optional) productCategory
                            parseFloat(item.unitPrice), // (Recommended) price
                            item.quantity // (Optional, defaults to 1) quantity
                        ]);
                    }
                }
            }

            // Pass the Cart's Total Value as a numeric parameter
            _paq.push(['trackEcommerceCartUpdate', parseFloat(Cart.getTotal(false))]);
        }
    };

    // init all
    App.Messages = Messages;
    App.Messages.init($('#quick-messages'));
    App.Utils = Utils;
    App.Cart = Cart;
    App.Cart.init();
    App._BrowserStorage = BrowserStorage;
    App.Storage = BrowserStorage.accept(['localStorage', 'cookie', 'sessionStorage']);

})(jQuery, App);
